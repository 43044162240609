import React from "react";
import Header from "../components/Header";
import Pricing from "./Pricing";
import Footer from "../components/Footer";

export default function Pricingpage() {
    return (
        <div className="pricing_route_page">
            <div>
            <Header />
            <Pricing />
            </div>
            <Footer/>
        </div>
    )
}