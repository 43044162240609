import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import { ApiRequests, paymentApiRequests } from '../service/ApiRequests'
import { lemon_squeezy_store_id } from "../constants";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const Pricing = () => {
  const [productsData, setProductsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const tokens = JSON.parse(localStorage.getItem('tokens')) || null
  const [annual, setAnnual] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate()
  console.log('tokens ', tokens)

  useEffect(() => {
    // getStore()
    getProduct();
  }, []);
  const getProduct = async () => {
    try {
      // const response = await axios.get('https://api.lemonsqueezy.com/v1/products/1', {
      // headers: {
      //   Accept: 'application/vnd.api+json',
      //   'Content-Type': 'application/vnd.api+json',
      //   Authorization: `Bearer ${apiKey}`,
      // },
      // });

      const { data } = await ApiRequests.getProducts({
        store_id: lemon_squeezy_store_id,   // live
        // store_id: 39021,
        token: tokens?.access?.token,
      });
      const _product = data?.data;
      // const finalProducts = _product?.slice(-6)
      // console.log('final products ', finalProducts)
      setProductsData(_product);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      // Handle the error
    }
  };

  console.log('products data ', productsData)


  const createPaymentMethod = async ({ id, store_id }) => {
    try {
      const response = await paymentApiRequests.checkOut({
        data: {
          type: "checkouts",
          attributes: {
            store_id,
            variant_id: 32232,
            custom_price: null,
            product_options: {
              name: "",
              description: "",
              media: [],
              redirect_url: "",
              receipt_button_text: "",
              receipt_link_url: "",
              receipt_thank_you_note: "",
              enabled_variants: [],
            },
            checkout_options: {
              embed: false,
              media: true,
              logo: true,
              desc: true,
              discount: true,
              dark: false,
              subscription_preview: true,
              button_color: "#7047EB",
            },
            checkout_data: {
              email: "",
              user: "",
              name: "",
              billing_address: {
                country: "",
                zip: "",
              },
              tax_number: "",
              discount_code: "",
              custom: {
                userId: user?.id
              }
            },
            expires_at: null,
          },
          relationships: {
            store: {
              data: {
                type: "stores",
                id: "32232",
              },
            },
            variant: {
              data: {
                type: "variants",
                id: "90864",
              },
            },
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="pricing_page">
      <div className="container pricing_container">
        <div className="pricing-padding">
          <div className="heading_box">
            <h2>Choose the Right Plan for You</h2>
            <p className="pt-3">Find the perfect plan to match your needs and enhance your teaching experience with Teacher’s Aid.</p>
          </div>

          {/* <div className='pricing_toggle_flex'>
            <h6>Lifetime</h6>
            <div class="checkbox-apple">
              <input
                class="yep"
                id="check-apple"
                type="checkbox"
                checked={annual}
                onChange={(e) => {
                  setAnnual(e.target.checked);
                }}
              />
              <label for="check-apple"></label>
            </div>
            <h6 className="pricing_heading_flex">Yearly <span>SAVE 25%</span></h6>
          </div> */}

          <div className="padding_cards">
            {loading ? <div style={{ color: 'white', textAlign: 'center' }}>Loading...</div> : <div className="row">

              {/* {!loading && <div>
                <div className="card">
                  <div className="card_heading">
                    <p>Free Plan</p>
                  </div>
                  <div className="card_billing">
                    <h1> <span>$</span> 0 <sub></sub></h1>
                    <p>Billed</p>
                  </div>

                  <div className="card_listing">
                    <div className="card_list_flexbox">
                      <div className="icon_box">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                      <p>5 Tags</p>
                    </div>
                    <div className="card_list_flexbox">
                      <div className="icon_box">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                      <p>5 Reminders</p>
                    </div>
                    <div className="card_list_flexbox">
                      <div className="icon_box">
                        <IoMdCheckmarkCircleOutline />
                      </div>
                      <p>5 Notes</p>
                    </div>
                  </div>

                  <div className="card_button_content_box">
                    <div className="white-bg-btn">
                      {user?.subscribed == false ? <button disabled={true}>
                        Free Plan
                      </button> : <button>
                        <img src="/images/chrome-store.png" alt="Chrome" />
                        Buy now
                      </button>}
                    </div>
                    <p className="pt-3">No credit card required</p>
                  </div>
                </div>
              </div>} */}
              {/* ?.filter((item) => {
                const productId = item?.id;

                // List of product IDs for beginner packages
                const annualPackeges = process.env.REACT_APP_ANNUAL_PACKEGES;

                // List of product IDs for annual packages
                const allTimePackeges = process.env.REACT_APP_LIFETIME_PACKEGES;

                // Check if the product is for beginners or annual based on the flag
                return (annual && annualPackeges.includes(productId)) || (!annual && allTimePackeges.includes(productId));
              }) */}
              {productsData && Array.isArray(productsData) && productsData?.map(
                ({
                  id,
                  attributes: {
                    buy_now_url,
                    created_at,
                    description,
                    from_price,
                    name,
                    pay_what_you_want,
                    price,
                    price_formatted,
                    slug,
                    status,
                    status_formatted,
                    store_id,
                  },
                }) => (
                  <div className="col-lg-6 col-sm-12 mb-4 responsive-padding ">
                    <div className="card">
                      <div className="card_heading">
                        <p>{name}</p>
                      </div>
                      <div className="card_billing">
                        <h1> <span>$</span> {price / 100} <sub>/ {(process.env.REACT_APP_LIFETIME_PACKEGES)?.includes(id) ? 'Once' : 'Yearly'}</sub></h1>
                        <p>{(process.env.REACT_APP_LIFETIME_PACKEGES)?.includes(id) ? 'Billed Once' : 'Billed Annual'}</p>
                      </div>

                      <div className="card_listing">

                        {/* <div  dangerouslySetInnerHTML={{ __html: description }} /> */}
                        <div>
                          {description?.split('</p>')?.map((item, index) => {
                            return (
                              item && <div className="card_list_flexbox">
                                <div className="icon_box">
                                <FaCheck />
                                  {/* <IoMdCheckmarkCircleOutline /> */}
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: item }} />
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      <div className="card_button_content_box">
                        <div className="white-bg-btn">
                          {user?.subscribed == true && user?.subscription?.productId == id ? <button disabled={true}>
                            {user?.subscription?.plan}
                          </button> : user?.subscribed ? <a onClick={() =>
                            createPaymentMethod({ id, store_id })
                          } href={buy_now_url +
                            `?checkout[email]=${user?.email}&checkout[name]=${user?.name}&checkout[custom][user_id]=${user?.id ? user?.id : user?._id}`
                          }>
                            <img src="/images/chrome-store.png" alt="Chrome" />
                            Upgrade
                          </a> : <a onClick={() =>
                            createPaymentMethod({ id, store_id })
                          } href={!user?.email ? `${process.env.REACT_APP_FRONTEND_HOST}#/signup?redirectTo=${buy_now_url}` :
                            buy_now_url +
                            `?checkout[email]=${user?.email}&checkout[name]=${user?.name}&checkout[custom][user_id]=${user?.id ? user?.id : user?._id}`
                          }>
                            <img src="/images/chrome-store.png" alt="Chrome" />
                            {user?.email ? 'Buy Now' : `Signup Today`}
                          </a>}
                        </div>
                        {/* <p className="pt-3">No credit card required</p> */}
                      </div>
                    </div>
                  </div>

                )
              )
              }

            </div>}
          </div>
        </div>
      </div>

      {/* Pricing page */}
      {/* <div className="global-padding pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="plan-card">
                <div className="plan-name-wrapper">
                  <div className="title">
                    <div className="icon"></div>
                  </div>
                  <div className="text">
                    <strong className="white">Beginner</strong>
                    <span className="white">Ideal for newcomers.</span>
                  </div>
                  <div className="price">
                    <h3 className="large text-white">
                      $5 <sub>/ per month</sub>
                    </h3>
                    <a className="btn-style get-started-btn">Get Started</a>
                  </div>
                </div>
                <div className="features-main-wrapper">
                  <strong className="white features-title">
                    What you will get
                  </strong>
                  <ul className="features-list">
                    <li>
                      <img src="images/chat-gpt-stars-icon.png" alt="" />
                      <span>Chat GPT/AI ready downloads</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>
                        Download <span className="white">3 profiles</span> per
                        month
                      </span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Download all profile data</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>
                        Download up to <span className="white">6000</span>{" "}
                        videos per month
                      </span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>
                        Scan and analyze{" "}
                        <span className="white"> 10 profiles</span>
                      </span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Backup: Tiktok only</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Full data reports &amp; analytics</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>No watermarks</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Downloadable .csv file</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>
                        Download speed: <span className="white">Average</span>
                      </span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Videos saved for: 24 hours</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="plan-card">
                <div className="plan-name-wrapper">
                  <div className="title">
                    <div className="icon_center"></div>
                  </div>
                  <div className="text">
                    <strong className="white">Creator</strong>
                    <span className="white">
                      Designed for growing creators.
                    </span>
                  </div>
                  <div className="price">
                    <h3 className="large text-white">
                      $8 <sub>/ per month</sub>
                    </h3>
                    <a className="btn-style get-started-btn_center">
                      Get Started
                    </a>
                  </div>
                </div>
                <div className="features-main-wrapper">
                  <strong className="white features-title">
                    What you will get
                  </strong>
                  <ul className="features-list">
                    <li>
                      <img src="images/chat-gpt-stars-icon.png" alt="" />
                      <span>Chat GPT/AI ready downloads</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>
                        Download <span className="white">10 profiles</span> per
                        month
                      </span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Download all profile data</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>
                        Download up to <span className="white">100k</span>{" "}
                        videos per month
                      </span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>
                        Scan and analyze{" "}
                        <span className="white"> 50 profiles</span>
                      </span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Backup: Tiktok only</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Full data reports &amp; analytics</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>No watermarks</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Downloadable .csv file</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>
                        Download speed: <span className="white">Fast</span>
                      </span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Videos saved for: 24 hours</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="plan-card">
                <div className="plan-name-wrapper">
                  <div className="title">
                    <div className="icon_right"></div>
                  </div>
                  <div className="text">
                    <strong className="white">Professional</strong>
                    <span className="white">Advanced tools for experts.</span>
                  </div>
                  <div className="price">
                    <h3 className="large text-white">
                      $15 <sub>/ per month</sub>
                    </h3>
                    <a className="btn-style get-started-btn_right">
                      Get Started
                    </a>
                  </div>
                </div>
                <div className="features-main-wrapper">
                  <strong className="white features-title">
                    What you will get
                  </strong>
                  <ul className="features-list">
                    <li>
                      <img src="images/chat-gpt-stars-icon.png" alt="" />
                      <span>Chat GPT/AI ready downloads</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>
                        Download <span className="white">100 profiles</span> per
                        month
                      </span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Download all profile data</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>
                        Download up to <span className="white">500k</span>{" "}
                        videos per month
                      </span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>
                        Scan and analyze{" "}
                        <span className="white">100 profiles</span>
                      </span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Backup: Tiktok only</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Full data reports &amp; analytics</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>No watermarks</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Downloadable .csv file</span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>
                        Download speed: <span className="white">Very Fast</span>
                      </span>
                    </li>
                    <li>
                      <img
                        src="images/tick-in-circle-icon.d7d3494c.svg"
                        alt=""
                        width="16"
                        height="16"
                      />
                      <span>Videos saved for: 24 hours</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Pricing;
