import Footer from "../components/Footer";
import Earnsection from "./Earnsection";
import Faqsection from "./Faqsection";
import Topbar from "./Topbar";

export default function Privatepolicy() {
    return (
        <div className="home_page_mian_div welcome_page refund-policy">

            <div className="bg-img-div">
                <Topbar />
                <div className="optimize_crm">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 my-auto">
                                <div className="optimize_crm_content">
                                    <h1 className="text-lg-start text-center">
                                    Privacy Policy
                                    </h1>

                                    <h6 className="py-4 pe-lg-2 text-lg-start text-center welcomeh6">
                                        <strong>Last Updated: </strong> 22/03/2022 (Version 1.1.12)
                                    </h6>

                                    <h6 className="text-lg-start text-center welcomeh6 py-0">
                                    This Privacy Policy applies only to our online activities <br /> and is valid for visitors to our website with regards to <br /> the information that they shared and/or collected in  <br /> Teacher’s Aid. This policy is not applicable to any <br /> information collected offline or via channels other <br /> than this website. </h6>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="img-right">
                                    <img
                                        src="/images/policy.svg"
                                        alt="Refund Policy"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Faqsection/>
            <Earnsection/>
            <Footer/>
        </div>
    )
}