import { Navbar, Nav } from 'react-bootstrap';

export default function Topbar() {
    return (
        <div className="navbar_section">
            <Navbar expand="lg" bg="transparent" variant="light">
                <div className="container">
                    <Navbar.Brand href="/">
                        <img src="/images/logo.svg" alt="Logo" className="logo-img" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarSupportedContent" />
                    <Navbar.Collapse id="navbarSupportedContent">
                        <Nav className="ms-auto pt-lg-0 pt-2 buttons-portion">
                            <Nav.Link className="white-bg-btn">
                                <button>
                                    <img src="/images/chrome-store.png" alt="Chrome" />
                                    Add to Chrome
                                </button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </div>
    )
}