import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Support() {
    return (
        <div className="support_route_page">
            <div className="home_page_mian_div">
                <div className="bg-img-div">
                    <Header />
                    <div className="optimize_crm">
                        <div className="container">
                            <div className="optimize_crm_content">
                                <img src="/images/nodata.jpg" alt="" className="nodata_img"/>
                                <h1 className="h1 text-center">
                                    We’re Almost Ready!
                                </h1>

                                <p className="py-4 pe-lg-2 text-center">
                                    Thank you for visiting! We're currently working hard to bring you a fresh new experience  on this page. Stay <br /> tuned for updates as we put the finishing touches on something exciting!
                                </p>

                                {/* <div className="button_content_box">
                                    <div className="gradiant-bg-btn">
                                        <a href="mailto:support@teachersaid.io">
                                            <img src="/images/chrome-store.png" alt="Chrome" />
                                            Contact us
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}