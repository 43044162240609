import { FaArrowRightLong } from "react-icons/fa6";

export default function Earnsection(){
    return(
        <div>
            <div className="earn_section">
        <div className="row">
          <div className="col-md-6 my-auto d-lg-block d-none">
            {/* <img src="/images/earn-image.png" alt="" /> */}
          </div>
          <div className="col-lg-6 my-auto center_box">
            <div className="contant_column">
              <h2>Earn 40% Commission!</h2>
              <p className="pt-3 pb-5">Sign up for our affiliate program and earn 40% commission for every new Teacher's Aid sign-up you refer. It’s our way of saying thank you for spreading the word!</p>
              <div className="btn_earn">
                <button onClick={() => window.open(`https://teachersaid.lemonsqueezy.com/affiliates`, '_blank')}>
                  Learn more <FaArrowRightLong />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
    )
}