import React, { useEffect } from "react";
import "../App.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Toast } from "react-bootstrap";
import Header from "../components/Header";
import Pricing from "./Pricing";
import Faqsection from "./Faqsection"
import { BiMessageRounded } from "react-icons/bi";
import { FaArrowRightLong } from "react-icons/fa6";
import Footer from "../components/Footer";
import Earnsection from "./Earnsection";

const HomePage = () => {
  let user = localStorage.getItem("user");
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  console.log("🚀 ~ HomePage ~ user:", user);
  const scrollToPricing = queryParams.get('scroll');

  const toast = () => Toast("Hello!");

  const scrollToPricingSection = (scroll = true) => {
    const section = document.getElementById('pricing-section');
    if (scroll == true && section) {
      // Scroll to the section smoothly
      section.scrollIntoView({ behavior: 'smooth', block: "end" });
    }
  }

  useEffect(() => {
    const section = document.getElementById('pricing-section');
    if (scrollToPricing === 'true' && section) {
      setTimeout(() => {
        section.scrollIntoView({ behavior: 'smooth' });
        
        // Remove the scrollTopricing flag from the URL
        queryParams.delete('scroll');
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
      }, 100); // Adjust the delay as needed (e.g., 100ms)
    }
  }, []);
  

  return (
    <div className="home_page_mian_div">
      {/* {user && JSON.parse(localStorage.getItem("user"))?.name && (
        <h3 className="download_heading">
          Go to google extension for download
        </h3>
      )} */}
      <div className="bg-img-div">
        <Header />
        <div className="optimize_crm">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 my-auto">
                <div className="optimize_crm_content">
                  <h1 className="h1 text-lg-start text-center">
                    {/* Optimize CRM, Sales & Manage Leads with Teacher's <span>Aid</span> */}
                    Never lose a Lead, <br /> Message, or Sale <br /> On Skool again!
                  </h1>

                  <p className="py-4 pe-lg-2 text-lg-start text-center">
                    Mini Skool Messenger CRM that helps you manage your <br /> inbox, follow up with prospects, get more sales & build your <br /> community.</p>

                  <div className="button_content_box">
                    <div className="gradiant-bg-btn">
                      <button onClick={() => scrollToPricingSection()}>
                        <img src="/images/chrome-store.png" alt="Chrome" />
                        Try today
                      </button>
                    </div>
                    <p className="pt-2 text-lg-start text-center">Teacher’s Aid turns conversations to conversions</p>
                  </div>
                </div>
                {user && user?.name ? (
                  <div>
                    <Link to="/login">
                      <button className="blue-btn me-2">Login</button>
                    </Link>
                    <button className="gray-btn">Try Now</button>
                  </div>
                ) : null}
              </div>
              <div className="col-lg-6">
                <div className="img-right hero_Section_img">
                  <img
                    src="/images/Grid3.png"
                    alt="hero frame"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="bg-img-skool-section">
        {/* Skool Section  */}
        <div className="skool_section">
          <div className="container">
            <div className="global-padding">
              <h2>
                Empowers you with seamless <span><img src="/images/skool.png" alt="skool" /></span> integration, helping <br /> you manage your community, CRM, sales, and leads—all <br /> organized, tracked, and always in sync.
              </h2>
            </div>
          </div>
        </div>

        {/* Cards Section */}

        <div className="cards_section">
          <div className="container">
            <div className="global-padding">
              <div className="heading_box">
                <h2>How Teacher’s Aid Makes Your Life Easier</h2>
                <p>Streamline tasks, enhance communication, and effortlessly manage your <br /> teaching needs with Teacher’s Aid.</p>
              </div>
              <div className="cards_grid_section">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 mt-3 responsive-padding">
                    <div className="card">
                      {/* <div className="icon_box">
                        <BiMessageRounded />
                      </div> */}
                      <div className="icon_img">
                        <img src="/images/messages.png" alt="Conversations" />
                      </div>
                      <h6>
                        Manage Your Conversations
                      </h6>
                      <p>Organize, tag, and manage your chats with Teacher’s Aid, enhancing CRM, sales, leads and community management.</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 mt-3 responsive-padding">
                    <div className="card">
                      {/* <div className="icon_box blue">
                        <img src="/images/clock.png" alt="clock" />
                      </div> */}
                      <div className="icon_img">
                        <img src="/images/clock-img.png" alt="Reminders" />
                      </div>
                      <h6>
                        Stay Organized with Reminders
                      </h6>
                      <p>Set reminders and notes to follow up on important tasks and conversations, ensuring nothing falls through the cracks.</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 mt-3 responsive-padding">
                    <div className="card">
                      {/* <div className="icon_box yellow">
                        <img src="/images/Huge-icon.png" alt="vector" />
                      </div> */}
                      <div className="icon_img">
                        <img src="/images/checkbox.png" alt="synchronization" />
                      </div>
                      <h6>
                        Role based team synchronization
                      </h6>
                      <p>Manage your team with profiles and roles. Assign, customize access, and ensure smooth and effective collaboration effortlessly.</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 mt-3 responsive-padding">
                    <div className="card">
                      {/* <div className="icon_box green">
                        <img src="/images/Vector.png" alt="vector" />
                      </div> */}
                      <div className="icon_img">
                        <img src="/images/vectors-img.png" alt="Setup" />
                      </div>
                      <h6>
                        Effortless Setup And Easy To Use
                      </h6>
                      <p>Get started quickly with an intuitive interface designed for ease of use. No complicated setup, just seamless integration.</p>
                    </div>
                  </div>
                </div>

                <div className="gradiant-bg-btn">
                  <button onClick={() => scrollToPricingSection()}>
                    <img src="/images/chrome-store.png" alt="Chrome" />
                    Start now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Grid Sections 1 */}

      <div className="grid_sections">
        <div className="container">
          <div className="global-padding">
            <div className="row">
              <div className="col-lg-6 col-xl-7 my-auto">
                <div className="img-left">
                  <img src="/images/Grid1.png" alt="improving CRM" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-xl-5 my-auto">
                <div className="grid_content ps-lg-2">
                  <h2>Streamline Your CRM with Teacher’s Aid</h2>
                  <p className="py-3">Optimize your CRM experience using Teacher's Aid for better organization and management of your contacts and conversations.</p>
                  <div className="flex_grid_content_box">
                    <div className="grid_listbox_flexbox">
                      <div className="icon_box">
                        <p>1</p>
                      </div>
                      <div className="grid_flex_content">
                        <p>Tag and Sort Conversations</p>
                        <span>Organize chats with customizable tags for easy retrieval and management.</span>
                      </div>
                    </div>

                    <div className="grid_listbox_flexbox">
                      <div className="icon_box">
                        <p>2</p>
                      </div>
                      <div className="grid_flex_content">
                        <p>Add and View Notes</p>
                        <span>Attach notes to conversations to keep track of important details and follow-ups.</span>
                      </div>
                    </div>

                    <div className="grid_listbox_flexbox">
                      <div className="icon_box">
                        <p>3</p>
                      </div>
                      <div className="grid_flex_content">
                        <p>Set Reminders</p>
                        <span>Create reminders for follow-ups and tasks to ensure nothing falls through the cracks.</span>
                      </div>
                    </div>

                  </div>

                  <div className="white-bg-btn">
                    <button onClick={() => scrollToPricingSection()}>
                      <img src="/images/chrome-store.png" alt="Chrome" />
                      Start improving your CRM organization
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Grid Sections 2 */}

      <div className="grid_sections">
        <div className="container">
          <div className="global-padding">
            <div className="row">
              <div className="col-lg-6 col-xl-5 my-auto order-2 order-lg-first">
                <div className="grid_content pe-lg-5">
                  <h2>Enhance Team Collaboration</h2>
                  <p className="py-3">Simplify teamwork and communication with features designed for effective collaboration.</p>
                  <div className="flex_grid_content_box">
                    <div className="grid_listbox_flexbox">
                      <div className="icon_box">
                        <p>1</p>
                      </div>
                      <div className="grid_flex_content">
                        <p>Seamless Team Synchronization</p>
                        <span>Easily sync and share tags and information with your team members.</span>
                      </div>
                    </div>

                    <div className="grid_listbox_flexbox">
                      <div className="icon_box">
                        <p>2</p>
                      </div>
                      <div className="grid_flex_content">
                        <p>Manage Roles and Permissions</p>
                        <span>Create role-based profiles and assign specific access levels for streamlined team management.</span>
                      </div>
                    </div>
                  </div>

                  <div className="white-bg-btn">
                    <button onClick={() => scrollToPricingSection()}>
                      <img src="/images/chrome-store.png" alt="Chrome" />
                      Start collaborating better
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-xl-7 my-auto order-1 order-lg-last">
                <div className="img-right m-0 mb-lg-0 mb-5">
                  <img src="/images/Grid2.png" alt="collaborate" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Grid Sections 3 */}

      <div className="grid_sections">
        <div className="container">
          <div className="global-padding">
            <div className="row">
              <div className="col-lg-6 col-xl-7 my-auto">
                <div className="img-left">
                  <img src="/images/Grid3.png" alt="leads management" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-xl-5 my-auto">
                <div className="grid_content ps-lg-2">
                  <h2>Boost Sales and Lead Management</h2>
                  <p className="py-3">Leverage Teacher’s Aid to enhance your sales processes and efficiently manage your leads and other communications.</p>
                  <div className="flex_grid_content_box">
                    <div className="grid_listbox_flexbox">
                      <div className="icon_box">
                        <p>1</p>
                      </div>
                      <div className="grid_flex_content">
                        <p>Track Lead Interactions</p>
                        <span>Monitor and record every interaction with leads to maintain a comprehensive view of your sales pipeline.</span>
                      </div>
                    </div>

                    <div className="grid_listbox_flexbox">
                      <div className="icon_box">
                        <p>2</p>
                      </div>
                      <div className="grid_flex_content">
                        <p>Set Automated Reminders</p>
                        <span>Schedule reminders to follow up with leads at optimal times, ensuring no opportunity slips through the cracks.</span>
                      </div>
                    </div>

                  </div>

                  <div className="white-bg-btn">
                    <button onClick={() => scrollToPricingSection()}>
                      <img src="/images/chrome-store.png" alt="Chrome" />
                      Start improving your leads management
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="pricing-section">
        <Pricing />
      </div>

      <Faqsection scrollToPricingSection={scrollToPricingSection} />

      {/* Earn Section */}
      <Earnsection />
      <Footer />
    </div>
  );
};

export default HomePage;
