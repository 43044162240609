import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios"; // Assuming you're using Axios to interact with your API
import { toast } from "react-toastify";
import Logincarousel from "../components/Carouselbox/Logincarousel";

export default function Feedback() {
    const [selectedReasons, setSelectedReasons] = useState([]);
    const [note, setNote] = useState("");
    const [loading, setLoading] = useState(false)
    const handleCheckboxChange = (e) => {
        const { name, checked, value } = e.target;
        if (checked) {
            setSelectedReasons(prev => [...prev, value]);
        } else {
            setSelectedReasons(prev => prev.filter(reason => reason !== value));
        }
    };

    const handleNoteChange = (e) => {
        setNote(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedReasons?.length == 0) {
            return
        }
        setLoading(true)
        const reasonsString = selectedReasons.join(", ");  // Join selected reasons as a comma-separated string

        const feedbackData = {
            reason: reasonsString,
            note: note,  // Send the note input
        };
        console.log('feed back data ', feedbackData)
        try {
            // Make an API request to send feedback
            const response = await axios.post(`${process.env.REACT_APP_contentBasePath}feedback/`, feedbackData); // Adjust the URL as needed
            const data = response.data
            if (response.status == 201 || response.status == 200) {
                toast.success('Feedback posted successfully')
                setLoading(false)
            }
        } catch (error) {
            console.error('Error submitting feedback:', error);
            toast.error(`${error?.response?.data?.message}`)
            setLoading(true)
        } finally {
            setLoading(false)
        }
    };

    return (
        <div className="Login_page">
            <div className="container-fluid" style={{ height: '100%' }}>
                <div className="row" style={{ height: '100%' }}>
                    <div className="col-lg-6 " style={{ height: '100%' }}>
                        <div className="login_card_column">
                            <div className="login_box">
                                <div>
                                    <Link to="/"><img src="/images/logo.svg" alt="Logo" className="logo-img" /></Link>
                                </div>
                                <div className="heading_box">
                                    <h2>Feedback Form</h2>
                                    <p>We are sorry to see you go. Your feedback is crucial to our growth, and we'd love to learn from you.</p>
                                </div>

                                <div className="login_input_field feedback_fields">
                                    <form className="form" onSubmit={handleSubmit}>
                                        <div>
                                            <div className="strong_div">
                                                <strong>Why are you uninstalling Teachersaid Chrome Extension?</strong>
                                            </div>
                                            <div className="forget_section feedback">
                                                <p className="d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        value="Does not work or works incorrectly"
                                                        className="me-1"
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    Does not work or works incorrectly
                                                </p>
                                                <p className="d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        value="Too complicated to use"
                                                        className="me-1"
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    Too complicated to use
                                                </p>
                                                <p className="d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        value="Lacks features important to me"
                                                        className="me-1"
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    Lacks features important to me
                                                </p>
                                                <p className="d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        value="No longer needed"
                                                        className="me-1"
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    No longer needed
                                                </p>
                                                <p className="d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        value="Pricing"
                                                        className="me-1"
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    Pricing
                                                </p>
                                                <p className="d-flex align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        value="Other"
                                                        className="me-1"
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    Other
                                                </p>
                                            </div>
                                        </div>

                                        <div className="text-area_input">
                                            <div className="textarea_flex">
                                                <label htmlFor="note"> <strong>Additional Comments (Note)</strong></label>
                                                <textarea
                                                    id="note"
                                                    name="note"
                                                    rows="3"
                                                    value={note}
                                                    onChange={handleNoteChange}
                                                    className="textarea_feedback"
                                                />
                                            </div>
                                        </div>
                                        <div className="gradiant-bg-btn">
                                            <button type="submit">
                                                {loading ? 'Loading...' : 'Send Feedback'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6" style={{ background: "#21367F", height: '100%' }}>
          <div className="carousal-slider-section">
            <div className="carousel_column">
              <Logincarousel />
            </div>
          </div>
        </div>
                </div>
            </div>
        </div>
    );
}
