import Footer from "../components/Footer";
import Earnsection from "./Earnsection";
import Faqsection from "./Faqsection";
import Topbar from "./Topbar";

export default function Refundpolicy() {
    return (
        <div className="home_page_mian_div welcome_page refund-policy">

            <div className="bg-img-div">
                <Topbar />
                <div className="optimize_crm">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 my-auto">
                                <div className="optimize_crm_content">
                                    <h1 className="text-lg-start text-center">
                                        Refund Policy
                                    </h1>

                                    <h6 className="py-4 pe-lg-2 text-lg-start text-center welcomeh6">
                                        <strong>Last Updated: </strong> 22/03/2022 (Version 1.1.12)
                                    </h6>

                                    <h6 className="text-lg-start text-center welcomeh6 py-0">
                                        You may cancel any paid services at any time you <br /> want, however you won't be issued a refund except in <br /> our sole discretion, or if legally required past our trial <br /> date. The goal of our free trial is to give you 30 days to <br /> try and test out Teacher’s Aid and make a decision if <br /> you're satisfied or not with the services to continue.
                                    </h6>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="img-right">
                                    <img
                                        src="/images/refund-policy.svg"
                                        alt="Refund Policy"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Faqsection />
            <Earnsection />
            <Footer />
        </div>
    )
}