// ** Reducers Imports
import auth from "./pagesSlices/authSlice";
import model from "./layoutSlices/modelSlice";
import user from "./pagesSlices/userSlice";

import error from "./errors/handleErrorsAndPayloads";
import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  user,
  error,
  auth,
  model,
});

export default rootReducer;
