import { React, useState } from "react";
// import "../../src/GlobalStyle.scss";
import "../App.css";
import * as Yup from "yup";
import { Apple, Google } from "@mui/icons-material";
import { Button } from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { userLoginAsyncThunk, userRegisterAsyncThunk } from "../redux/pagesSlices/authSlice";
import axios from "axios";
import { BsFillChatTextFill } from "react-icons/bs";
import { IoMdUnlock } from "react-icons/io";
import Logincarousel from "./Carouselbox/Logincarousel";
import { toast } from "react-toastify";
import { FaUser } from "react-icons/fa6";

export default function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const location = useLocation()
  const d = useDispatch()
  const queryParams = new URLSearchParams(location.search);

  const userEmail = queryParams.get('email')
  const userRole = queryParams.get('role')
  const profileName = queryParams.get('profileName')
  const teamName = queryParams.get('teamId')
  const parentUser = queryParams.get('parentUser');
  const redirectTo = queryParams.get('redirectTo')

  console.log(userEmail, userRole, profileName, teamName)

  const handleSignup = async (event) => {
    event.preventDefault();
    setError(""); // Reset error message

    if ((!email && !userEmail) || !name || !password) {
      setError("Please fill in all fields");
      return;
    }
    setLoading(true)
    try {
      if (userEmail && userRole && profileName && teamName) {
        const response = await axios.post(
          `${process.env.REACT_APP_contentBasePath}auth/register`,
          {
            name: name,
            email: userEmail,
            password: password,
            teamId: teamName,
            role: userRole,
            setupComplete: true,
            parentUser: parentUser,
            profileName: JSON.parse(profileName)
          }
        );

        // Assuming the API returns a token on successful registration
        console.log("Registration successful", response.data);
        // Store the token here (e.g., in localStorage)
        localStorage.setItem("token", response.data.token);
        toast.success('You are all set! Registered Successfully')
        setLoading(false)
        // Redirect to login page or dashboard as needed
        navigate("/login");
        window.open(`${process.env.REACT_APP_FRONTEND_HOST}#/welcome`, '_blank')
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_contentBasePath}auth/register`,
          {
            name: name,
            email: email,
            password: password,
          }
        );
        if (response.status == 201) {
          setLoading(false)
          console.log("Registration successful", response.data);
          // Store the token here (e.g., in localStorage)
          localStorage.setItem("token", response.data.token);
          toast.success('Registration successful')
          const data = response.data
          const { payload } = await d(userLoginAsyncThunk({ email: data?.user?.email, password }))
          console.log('payload signin ', payload)
          if (redirectTo) {
            window.location.href = `${redirectTo}?checkout[email]=${data?.user?.email}&checkout[name]=${data?.user?.name}&checkout[custom][user_id]=${data?.user?.id ? data?.user?.id : data?.user?._id}`
          }
          else if (payload?.user) {
            // navigate("/pricing");
            window.location.href=`${process.env.REACT_APP_FRONTEND_HOST}#/?scroll=true`
          }
          // Redirect to login page or dashboard as needed
        }

      }
    } catch (err) {
      // Handle errors (e.g., show error message)
      // setError(
      //   err.response ? err.response.data.message : "Registration failed"
      // );
      toast.error(`${err.response.data.message}`)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className="container-fluid" style={{ height: '100%' }}>
      <div className="row" style={{ height: '100%' }}>
        <div className="col-lg-6 " style={{ height: '100%' }}>
          <div className="login_card_column">
            <div className="login_box">
              <div>
              <Link to="/"><img src="/images/logo.svg" alt="logo" className="logo-img" /></Link>  
              </div>
              <div className="heading_box">
                <h2>Welcome to Teacher’s <span>Aid</span> </h2>
                <p>Welcome to Teachers Aid! 🎉 We're here to help you stay organized and efficient. Happy tagging! 😊</p>
              </div>
              <div className="login_input_field">
                {error && <p className="text-danger">{error}</p>}
                <form onSubmit={handleSignup} className="form">
                  <div className="form-group">
                    <div className="field_icon_img">
                      <FaUser />
                    </div>
                    <input
                      type="name"
                      className="input-form-box"
                      id="name"
                      name="name"
                      placeholder="Enter name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group my-4">
                    <div className="field_icon_img">
                      <BsFillChatTextFill />
                    </div>
                    <input
                      type="email"
                      className="input-form-box"
                      id="email"
                      name="email"
                      placeholder="Enter your email here"
                      value={userEmail ? userEmail : email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group my-4">
                    <div className="field_icon_img">
                      <IoMdUnlock />
                    </div>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Enter your password here"
                      className="input-form-box"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="gradiant-bg-btn">
                    <button disabled={loading} className="blue-btn w-100 text-center" type="submit">
                      {loading ? 'Loading...' : 'Signup'}
                    </button>
                  </div>
                </form>

                <div className="login_footer">
                  {/* <p className="text-center py-3" style={{ color: "#979797" }}>or sign in with</p> */}
                  {/* <div className="button_flex_box">
                    <div className="white_btn">
                      <button>
                        <img src="/images/google.png" alt="facebook" className="google_img" />
                        Google
                      </button>
                    </div>
                    <div className="white_btn">
                      <button>
                        <img src="/images/fb.png" alt="facebook" />
                        Facebook
                      </button>
                    </div>
                  </div> */}
                  <div className="singup_link">
                    <p className="text-center pt-3">
                      Already have an account?
                      <a href={`${process.env.REACT_APP_FRONTEND_HOST}#/login${redirectTo ? `?redirectTo=${redirectTo}` : ''}`}>
                        Log in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6" style={{ background: "#21367F", height: '100%' }}>
          <div className="carousal-slider-section">

            <div className="carousel_column">
              <Logincarousel />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
