import React from "react";

export default function Mailcheck() {
    return (
        <div className="Login_page forgot_responsive">
            <div className="login_card_column">
                <div className="login_box">
                    <div className="container-fluid">
                        <div>
                            <img src="/images/envelop.png" alt="envelop" className="lock-img" />
                        </div>
                        <div className="heading_box text-center">
                            <h2>Check your mail</h2>
                            <p>We sent a link to reset your password to <br /> <a>useremail@gmail.com</a></p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}