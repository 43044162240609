import { React, useState } from "react";
import * as Yup from "yup";
import "../App.css";
import { Apple, Google } from "@mui/icons-material";
import { Button } from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { userLoginAsyncThunk } from "../redux/pagesSlices/authSlice";
import { Link, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import Logincarousel from "./Carouselbox/Logincarousel";
import { BsFillChatTextFill } from "react-icons/bs";
import { IoMdUnlock } from "react-icons/io";
import { toast } from "react-toastify";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);

  const redirectTo = queryParams.get('redirectTo')

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(""); // Reset error message

    if (!email || !password) {
      setError("Please fill in all fields");
      return;
    }
    setLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_contentBasePath}auth/login`,
        {
          email: email,
          password: password,
        }
      );
      const user = response.data.user
      // Assuming the API returns a token on successful login
      console.log("Login successful", response.data);
      // Store the token here (e.g., in localStorage)
      localStorage.setItem("tokens", JSON.stringify(response.data.tokens));
      localStorage.setItem("user", JSON.stringify(response.data.user));
      toast.success('Login Successfully!')
      setLoading(false)
      // Redirect to home page or dashboard as needed
      if(redirectTo){
        window.location.href = `${redirectTo}?checkout[email]=${user?.email}&checkout[name]=${user?.name}&checkout[custom][user_id]=${user?.id ? user?.id : user?._id}`
      }else{
        navigate("/");
      }
    } catch (err) {
      // Handle errors (e.g., show error message)
      // setError(err.response ? err.response.data.message : "Login failed");
      toast.error(`${err.response.data.message}`)
      setLoading(false)
    }finally{
      setLoading(false)
    }
  };

  return (
    <div className="container-fluid" style={{ height: '100%' }}>
      <div className="row" style={{ height: '100%' }}>
        <div className="col-lg-6 " style={{ height: '100%' }}>
          <div className="login_card_column">
            <div className="login_box">
              <div>
               <Link to="/"><img src="/images/logo.svg" alt="logo" className="logo-img" /></Link>  
              </div>
              <div className="heading_box">
                <h2>Log in to your Account</h2>
                <p>Welcome back! Select method to log in</p>
              </div>

              <div className="login_input_field">
                <form onSubmit={handleLogin} className="form">
                  <div className="form-group">
                    <div className="field_icon_img">
                      <BsFillChatTextFill />
                    </div>
                    <input
                      type="email"
                      className="input-form-box"
                      id="email"
                      name="email"
                      placeholder="Enter your email here"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="form-group mt-3">
                    <div className="field_icon_img">
                      <IoMdUnlock />
                    </div>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Enter your password here"
                      className="input-form-box"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="forget_section py-3">
                    <p className="d-flex align-items-center">
                      <input type="checkbox" className="me-1" />
                      Remember me
                    </p>

                    <Link to="/forgot-password">
                      Forgot password?
                    </Link>
                  </div>
                  <div className="gradiant-bg-btn">
                    <button disabled={loading} type="submit">
                      {loading ? 'Loading...' : 'Sign In'}
                    </button>
                  </div>
                </form>
                <div className="login_footer">
                  {/* <p className="text-center py-3" style={{ color: "#979797" }}>or</p> */}
                  {/* <div className="button_flex_box">
                    <div className="white_btn">
                      <button>
                        <img src="/images/google.png" alt="facebook" className="google_img" />
                        Google
                      </button>
                    </div>
                    <div className="white_btn">
                      <button>
                        <img src="/images/fb.png" alt="facebook" />
                        Facebook
                      </button>
                    </div>
                  </div> */}
                  <div className="singup_link">
                    <p className="text-center pt-3">
                      Don't have an account?
                      <a href={`${process.env.REACT_APP_FRONTEND_HOST}#/signup${redirectTo ? `?redirectTo=${redirectTo}` : ''}`}>
                        Create an account
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="col-lg-6" style={{ background: "#21367F", height: '100%' }}>
          <div className="carousal-slider-section">
            <div className="carousel_column">
              <Logincarousel />
            </div>
          </div>
        </div>
      </div>
      {error && <p className="text-danger">{error}</p>}
    </div>

  );
}
