import { React, useState } from "react";
import * as Yup from "yup";
import "../App.css";
import { Apple, Google } from "@mui/icons-material";
import { Button } from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { userLoginAsyncThunk } from "../redux/pagesSlices/authSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { IoMdUnlock } from "react-icons/io";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get('token');

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(""); // Reset error message

    if (!password) {
      setError("Please fill in all fields");
      return;
    }
    setLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_contentBasePath}auth/reset-password`,
        {
          password: password
        },
        {
          params: { token }
        }
      );
      toast.success('Password updated!')
      setLoading(false)
      navigate("/login");
    } catch (err) {
      // Handle errors (e.g., show error message)
      // setError(err.response ? err.response.data.message : "Login failed");
      toast.error(`${err.response.data.message}`)
      setLoading(false)
    }finally{
      setLoading(false)
    }
  };

  return (
    <div className="login_card_column">
      <div className="login_box">
        <div className="container-fluid">
          <div>
            <img src="/images/reset-lock.png" alt="lock" className="lock-img" />
          </div>
          <div className="heading_box text-center">
            <h2>Set new password</h2>
            <p>Must be at least 8 characters</p>
          </div>
          <div className="login_input_field">
            {error && <p className="text-danger">{error}</p>}
            <form onSubmit={handleLogin} className="form">
              <div className="form-group">
                <div className="field_icon_img">
                  <IoMdUnlock />
                </div>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Enter your new password here"
                  className="input-form-box"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="gradiant-bg-btn my-3">
                <button disabled={loading} type="submit">
                {loading ? 'Loading...' : 'Set new password'}
                </button>
              </div>

            </form>
            {/* <p className="text-center py-3">
              Forgot password?{" "}
              <Link to="/forgot-password" style={{ color: "#007BFC" }}>
                Reset
              </Link>
            </p>
            <p className="text-center py-3">
              Don't have an account?{" "}
              <Link to="/signup" style={{ color: "#007BFC" }}>
                Signup
              </Link>
            </p> */}
          
          <div className="login_footer">
            <div className="singup_link">
              <p className="text-center">
                Back to
                <Link to="/login">
                  Sign in
                </Link>
              </p>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
