import React from "react";
import { Link } from "react-router-dom";

export default function Alldone() {
    return (
        <div className="Login_page forgot_responsive">
            <div className="login_card_column">
                <div className="login_box">
                    <div className="container-fluid">
                        <div>
                            <img src="/images/done.png" alt="thumb" className="lock-img" />
                        </div>
                        <div className="heading_box text-center">
                            <h2>All done!</h2>
                            <p>You password has been successfully updated</p>
                        </div>
                        <div className="all_done_btn">
                            <Link to="/login">
                                Back to Sign in
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}