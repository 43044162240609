import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Logincarousel() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,   
        autoplaySpeed: 4000,
    };
    return (
        <Slider {...settings}>
            <div className="carousel_box_slider">
                <div className="carousel_images">
                <img src="/images/Grid1.png" alt="Slider-img" className="img-fluid" />
                </div>
                <div className="heading_box">
                    <h6>Streamline Your CRM with Teacher’s Aid</h6>
                    <p>Optimize your CRM experience using Teacher's Aid for better organization and management of your contacts and conversations.</p>
                </div>
            </div>

            <div className="carousel_box_slider">
            <div className="carousel_images">
                <img src="/images/Grid2.png" alt="Slider-img" className="img-fluid" />
                </div>
                <div className="heading_box">
                    <h6>Enhance Team Collaboration</h6>
                    <p>Simplify teamwork and communication with features designed for effective collaboration.</p>
                </div>
            </div>

            <div className="carousel_box_slider">
            <div className="carousel_images">
                <img src="/images/Grid3.png" alt="Slider-img" className="img-fluid" />
                </div>
                <div className="heading_box">
                    <h6>Boost Sales and Lead Management</h6>
                    <p>Leverage Teacher’s Aid to enhance your sales processes and efficiently manage your leads and other communications.</p>
                </div>
            </div>
        </Slider>
    );
}