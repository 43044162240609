import React from "react";
import ForgotPassword from "../../components/ForgotPassword";
// import { useAuth } from "../../hook/useAuth";
import { Navigate } from "react-router-dom";

export default function Forgotpassword() {
  // useAuth();
  return (
    <div className="Login_page forgot_responsive">
      <ForgotPassword />
    </div>
  );
}
