const localhost = process.env.REACT_APP_NODE_ENV === "localhost";
const development = process.env.REACT_APP_NODE_ENV === "development";
export const app_mode = localhost
  ? "localhost"
  : development
    ? "development"
    : "production";

export const contentPath =
  process.env.REACT_APP_contentBasePath ??
  (localhost
    ? "http://localhost:6063/content/api/"
    : development
      ? "http://localhost:6063/content/api/"
      : "http://localhost:6063/content/api/"
  );

export const apiKey = ''

export const paymentBasePath = "https://api.lemonsqueezy.com/v1/";

export const lemon_squeezy_store_id = process.env.REACT_APP_STORE_ID
export const APIurls = {
  // auth
  login: "auth/login",
  refreshTokens: "auth/refresh-tokens",
  register: "auth/register",
  logout: "auth/logout",
  googleLogout: "auth/google/logout",
  authenticate: "auth/authenticate",
  subscription: 'subscription'
};

export const paymentAPIurls = {
  getStores: "stores",
  getProducts: "products",
  checkouts: "checkouts",
  customers: "customers",
};
