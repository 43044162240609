import { React, useState } from "react";
import * as Yup from "yup";
import "../App.css";
import { Apple, Google } from "@mui/icons-material";
import { Button } from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { userLoginAsyncThunk } from "../redux/pagesSlices/authSlice";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { BsFillChatTextFill } from "react-icons/bs";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] =  useState(false);
  const navigate = useNavigate();

  const handleSendEmail = async (event) => {
    event.preventDefault();
    setError(""); // Reset error message

    if (!email) {
      setError("Please fill in all fields");
      return;
    }
    setLoading(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_contentBasePath}auth/forgot-password`,
        {
          email: email,
        }
      );
      toast.success('Reset Password link sent to your email');
      setLoading(false)
    } catch (err) {
      // Handle errors (e.g., show error message)
      // setError(err.response ? err.response.data.message : "Login failed");
      toast.error(`${err.response.data.message}`)
      setLoading(false)
    }finally{
      setLoading(false)
    }
  };

  return (
    <div className="login_card_column">
      <div className="login_box">
        <div className="container-fluid">
          <div>
            <img src="/images/lock.png" alt="lock" className="lock-img" />
          </div>
          <div className="heading_box text-center">
            <h2>Forgot Password</h2>
            <p>No worries we’ll handle it</p>
          </div>
          <div className="login_input_field">
            {error && <p className="text-danger">{error}</p>}
            <form onSubmit={handleSendEmail} className="form">
              <div className="form-group">
                <div className="field_icon_img">
                  <BsFillChatTextFill />
                </div>
                <input
                  type="email"
                  className="input-form-box"
                  id="email"
                  name="email"
                  placeholder="Enter your email here"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="gradiant-bg-btn my-3">
                <button disabled={loading} type="submit">
                  {loading ? 'Loading...' : 'Continue'}
                </button>
              </div>
            </form>

            <div className="login_footer">
              <div className="singup_link">
                <p className="text-center">
                  Back to
                  <Link to="/signup">
                     Sign in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
