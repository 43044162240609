import React from "react";
import ResetPassword from "../../components/ResetPassword";
// import { useAuth } from "../../hook/useAuth";
import { Navigate } from "react-router-dom";

export default function Resetpassword() {
  // useAuth();
  return (
    <div className="Login_page forgot_responsive">
      <ResetPassword />
    </div>
  );
}
